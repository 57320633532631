import Pusher from "pusher-js";
import Echo from "laravel-echo";

window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: "reverb",
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: window.location.hostname,
    wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
    wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
    forceTLS: import.meta.env.REVERB_SCHEME !== "https",
    enabledTransports: ["ws", "wss"],
    disableStats: true,
});
